export const baseAddress = 'http://localhost:8080'


export const memberList = '/list'

export const test = '/test'

export const login = '/login'

export const updateUserInfo = '/updateUser'

export const addUserInfo = '/addUser'

export const getMenuListByRoleId = '/getMenuListByRoleId'

export const getAllMenuByRoleId = '/getAllMenuByRoleId'

export const deleteUserById = '/deleteUserById'

export const getDepartmentList = '/getDepartmentList'

export const addDepartment = '/addDepartment'

export const getRoleList = '/getRoleList'

export const getMenuList = '/getMenuList'

export const getParentMenuList = '/getParentMenuList'

export const getTableList = '/getTableList'

export const getCardList = '/getCardList'

export const getCommentList = '/getCommentList'


const baseUrl = 'http://38.181.52.159:8960/'// 线上
// const baseUrl = 'http://114.55.89.10:8960/' // 测试

function getRequestUrl() {
  // return 'https://'+url+'/';
  return 'http://'+url+'/';
}



// module.exports = {
// 	getUrl : getRequestUrl,
// 	getWebsokcetUrl:getWebsokcetUrl,
// }
export const getUrl = getRequestUrl
export {baseUrl}
