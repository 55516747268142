import { baseAddress } from '@/api/url'
import Mock from 'mockjs'
import { baseData } from '../base'

export const adminRoutes = [
  {
    menuUrl: '/authority',
    menuName: '会员管理',
    icon: 'system',
    tip: 'new',
    children: [
      {
        menuUrl: '/authority/user',
        menuName: '会员列表',
        tip: 'new'
      },
      {
        menuUrl: '/authority/department',
        menuName: '抢单管理',
        tip: 'circle'
      }
      // {
      //   menuUrl: '/authority/role',
      //   menuName: '角色管理',
      //   tip: '12'
      // },
      // {
      //   menuUrl: '/authority/menu',
      //   menuName: '菜单管理'
      // }
    ]
  },
  {
    menuUrl: '/list1',
    menuName: '业务员管理',
    icon: 'list',
    children: [
      {
        menuUrl: '/list/table1',
        menuName: '业务员列表'
      },
      {
        menuUrl: '/list/table-with-search1',
        menuName: '账户明细'
      },
      {
        menuUrl: '/list/table-custom1',
        menuName: '我的提现'
      }
      // {
      //   menuUrl: '/list/grid-list',
      //   menuName: '卡片列表'
      // }
    ]
  },
  {
    menuUrl: '/list',
    menuName: '交易管理',
    icon: 'list',
    children: [
      {
        menuUrl: '/list/table',
        menuName: '订单列表'
      },
      {
        menuUrl: '/list/table-with-search',
        menuName: '充值管理'
      },
      {
        menuUrl: '/list/table-custom',
        menuName: '提现管理'
      }
      // {
      //   menuUrl: '/list/grid-list',
      //   menuName: '卡片列表'
      // }
    ]
  }
]
 
 
export const editorRoutes = [
  {
    menuUrl: '/list',
    menuName: '列表页面',
    icon: 'list',
    children: [
      {
        menuUrl: '/list/table',
        menuName: '表格'
      },
      {
        menuUrl: '/list/table-with-search',
        menuName: '表格搜索'
      },
      {
        menuUrl: '/list/grid-list',
        menuName: '卡片列表'
      }
    ]
  },
  {
    menuUrl: '/form',
    menuName: '表单页面',
    tip: 'circle',
    icon: 'form',
    children: [
      {
        menuUrl: '/form/base-form-view',
        menuName: '基本表单',
        cacheable: true
      },
      {
        menuUrl: '/form/advance-form',
        menuName: '高级表单',
        cacheable: true
      },
      {
        menuUrl: '/form/step-form',
        menuName: '分步表单'
      },
      {
        menuUrl: '/form/tip',
        menuName: '通知提示'
      }
    ]
  },
  {
    menuUrl: '/editor',
    menuName: '编辑器',
    tip: '12',
    icon: 'editor',
    children: [
      {
        menuUrl: '/editor/rich-text',
        menuName: '富文本'
      },
      {
        menuUrl: '/editor/markdown',
        menuName: 'markdown'
      }
    ]
  },
  {
    menuUrl: '/other',
    menuName: '其它功能',
    children: [
      {
        menuUrl: '/other/print',
        menuName: '打印'
      },
      {
        menuUrl: 'http://www.baidu.com',
        menuName: '外链'
      },
      {
        menuUrl: '/other/qrcode',
        menuName: '二维码'
      },
      {
        menuUrl: '/other/css-animation',
        menuName: 'Css动画'
      }
    ]
  }
]

Mock.mock(baseAddress + '/getMenuList', function () {
  baseData.data = adminRoutes
  return Mock.mock(baseData)
})
