
/**
 * 交易相关接口
 * */
import fetch from '../utils/fetch.js'

// 商品列表
export function goodsList(query) {
  return fetch({
    url: '/api/goods/list',
    method: 'post',
    data: query
  })
}
// 添加商品
export function addGoods(query) {
  return fetch({
    url: '/api/goods/add',
    method: 'post',
    data: query
  })
}
// 编辑商品
export function updateGoods(query) {
  return fetch({
    url: '/api/goods/update',
    method: 'post',
    data: query
  })
}
// 删除商品
export function deleteGoods(query) {
  return fetch({
    url: '/api/goods/delete',
    method: 'post',
    data: query
  })
}




// 商品分类列表
export function goodsTypeList(query) {
  return fetch({
    url: '/api/goods/typeList',
    method: 'post',
    data: query
  })
}

// 添加商品分类
export function addGoodsType(query) {
  return fetch({
    url: '/api/goods/addGoodsType',
    method: 'post',
    data: query
  })
}

// 编辑商品分类
export function updateGoodsType(query) {
  return fetch({
    url: '/api/goods/updateGoodsType',
    method: 'post',
    data: query
  })
}

// 删除商品分类
export function deleteGoodsType(query) {
  return fetch({
    url: '/api/goods/deleteGoodsType',
    method: 'post',
    data: query
  })
}
export function addTopUp(query) {
  return fetch({
    url: '/api/topup/addTopUp',
    method: 'post',
    data: query
  })
}
// 订单列表
export function orderList(query) {
  return fetch({
    url: '/api/order/list',
    method: 'post',
    data: query
  })
}
// 冻结
export function orderFreeze(query) {
  return fetch({
    url: '/api/order/freeze',
    method: 'post',
    data: query
  })
}
// 强制付款
export function orderForcePay(query) {
  return fetch({
    url: '/api/order/forcePay',
    method: 'post',
    data: query
  })
}
// 强制删除
export function orderDelete(query) {
  return fetch({
    url: '/api/order/delete',
    method: 'post',
    data: query
  })
}

export function topAndWithdrawal(query) {
  return fetch({
    url: '/api/data/topAndWithdrawal',
    method: 'get',
    data: query
  })
}

