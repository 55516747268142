import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './icons'
import './utils'
import '@/styles/index.scss'
import './api/http'

// import '../mock'

import '@/components/common'

import LayoutStore from '@/layouts/index'
import { resetRouter } from './router/index'
import Cookies from 'js-cookie'
// import { Message } from 'element-ui';
// //定义一个新的Message方法，多传入一个offset参数
// const $message = options => {
//   return Message({
//     ...options,
//     offset: 300
//   });
// }
// //重写方法,将offset写入options
// ['success', 'warning', 'info', 'error'].forEach(type => {
//   $message[type] = options => {
//     if (typeof options === 'string') {
//       options = {
//         message: options,
//         offset: 200
//       };
//     }
//     options.type = type;
//     return Message(options);
//   };
// });
// //将$message挂载到this上
// Vue.prototype.$message = $message;
// //不加这行代码运行this.$message.closeAll时会报错
// Vue.prototype.$message.closeAll = Message.closeAll

Vue.use(LayoutStore, {
  state: {
    isFixedNavBar: true,
    layoutMode: 'ltr',
    themeColor: 'theme_color_blue',
    theme: 'dark-side'
  },
  actions: {
    onLogout() {
      resetRouter()
      Cookies.remove('admin-token')
      router.replace({ name: 'login' })
    },
    toPersonalCenter() {
      router.push('/personal/center')
    }
  }
})

Vue.config.productionTip = false
// 导航守卫
// 使用 router.beforeEach 注册一个全局前置守卫，判断用户是否登陆
// router.beforeEach((to,from,next) => {
//   if(to.path === '/'){
//     next();
//   }else{
//  console.log(' window.localStorage',  window.localStorage)
//     let token = window.localStorage.token;
//     console.log('main.js token', token)
//     if(token === 'null' || token === '' || token === undefined){
//       next('/')
//     }else{
//       next();
//     }
//   }
// })
// 在页面即将刷新前触发
window.addEventListener('beforeunload', () => {
  // 将vuex中数据存储起来
  localStorage.setItem('isCollapse', LayoutStore.state.isCollapse)
})
// 在页面加载时 从存储中获取数据
const isCollapse = localStorage.getItem('isCollapse')
if (isCollapse == 'true') {
  LayoutStore.toggleCollapse(true)
} else {
  LayoutStore.toggleCollapse(false)
}
new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
