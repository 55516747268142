
<template>
<!-- 头部 -->
  <el-breadcrumb separator-class="el-icon-arrow-right" style="margin-left: 20px">
    <transition-group name="breadcrumb" style="height: 45px; line-height: 45px">
      <el-breadcrumb-item
        v-for="item of breadcrumbs"
        :key="item.path"
      >
        <span class="breadcrumb-title" style="color: #333">{{ item.meta.title }}</span>
      </el-breadcrumb-item>
    </transition-group>
  </el-breadcrumb>
</template>

<script>
import store from '../store/index'
export default {
  name: 'Breadcrumb',
  data() {
    return {
      state: store.state,
      breadcrumbs: []
    }
  },
  watch: {
    $route() {
      this.generatorBreadcrumb()
    }
  },
  mounted() {
    this.generatorBreadcrumb()
  },
  methods: {
    generatorBreadcrumb() {
      this.breadcrumbs = this.$route.matched.filter((it) => {
        return it.meta && it.meta.title
      })
    }
  }
}
</script>
