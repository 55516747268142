<template>
  <div class="vaw-avatar-container" >
    <el-dropdown trigger="hover" @command="onCommad">
      <div class="action-wrapper">
        <!-- <div class="avatar">
          <img :src="avatar" />
        </div> -->
        <span class="nick-name el-dropdown-link">
          <span style="color: white">{{ account }}</span>
          <i style="color: white" class="el-icon-arrow-down tip"></i>
        </span>
      </div>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item icon="el-icon-user" command="personalCenter">
          修改密码
        </el-dropdown-item>
        <el-dropdown-item icon="el-icon-switch-button" command="logout">
          退出登录
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>

        <el-dialog
        append-to-body
  title="修改密码"
  :visible.sync="dialogVisible"
  width="30%"
 >

  <el-form size="mini" style="width: 300px"  ref="form" :model="form" label-width="80px">
  <el-form-item label="原始密码:">
    <el-input placeholder="请输入原始密码" type="password" v-model="form.password"></el-input>
  </el-form-item>

  <el-form-item label="新密码:">
    <el-input   placeholder="请输入新密码" type="password" v-model="form.newPassword"></el-input>
  </el-form-item>
  <el-form-item label="确认密码:">
    <el-input   placeholder="请输入确认密码" type="password" v-model="form.newPasswordNew"></el-input>
  </el-form-item>

</el-form>
  <span slot="footer" class="dialog-footer">
    <el-button size="mini" @click="dialogVisible = false">取 消</el-button>
    <el-button size="mini" type="primary" @click="confirmEdit(form)">提 交</el-button>
  </span>
</el-dialog>

  </div>
</template>

<script>
import store from '../store/index'
import { MessageBox } from 'element-ui'
import { mapGetters } from 'vuex'
import {revisePwd} from '../../api/index'
import md5 from 'crypto-js/md5';
export default {
  name: 'VAWAvatar',
  data() {
    return {
      state: store.state,
      account: '',
      dialogVisible: false,
      form: {
        password: '',
        newPassword: '',
        newPasswordNew: ''
      }

    }
  },
 mounted() {
  this.account = window.localStorage.account
 },
  computed: {
    ...mapGetters({
      avatar: 'user/getAvatar',
      nickName: 'user/getNickName'

    })
  },
  methods: {
    onCommad(command) {
      switch (command) {
        case 'personalCenter':
          this.onPersonalCenter()
          break
        case 'logout':
          this.onLogout()
          break
      }
    },
    // 修改密码
    onPersonalCenter() {
      this.dialogVisible = true
      // store.toPersonalCenter && store.toPersonalCenter()

    },
    setmd5(val) {
      if (val) {
        return md5(val).toString()
      } else {
        return ''
      }
    },
    confirmEdit() {
      if (!this.form.password) {
        this.$errorMsg('请输入原始密码')
        return
      }
      if (!this.form.newPassword) {
        this.$errorMsg('请输入新密码')
        return
      }
      if (!this.form.newPasswordNew) {
        this.$errorMsg('请输入确认密码')
        return
      }
      if ((this.form.password && this.form.password.length < 6) || (this.form.newPassword && this.form.newPassword.length < 6) || (this.form.newPasswordNew && this.form.newPasswordNew.length < 6)) {
        this.$errorMsg('密码需要六位及以上')
        return
      }
      if (this.form.newPassword !== this.form.newPasswordNew) {
        this.$errorMsg('新密码与确认密码需保持一致')
        return
      }

      revisePwd({account: window.localStorage.account, password: this.setmd5(this.form.password), newPassword: this.setmd5(this.form.newPassword)}).then(res => {
        if (res.data.status == 0) {
          this.$message({
            message: '修改成功',
            type: 'success'
          })
          this.$router.push({path: '/'})
        } else {
          this.$message({
            message:res.data.result,
            type: 'error'
          })
        }
      })
    },
    onLogout() {
      MessageBox.confirm('是否要退出登录？', '提示')
        .then(_ => {
          localStorage.clear()
          store.logout()
          store.onLogout && store.onLogout()

     store.dispatch('user/setUserToken', '')

      store.dispatch('user/setAccount', '')
    localStorage.removeItem('account')
        })
        .catch(_ => {})
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/styles/variables.scss";
.vaw-avatar-container {
  .action-wrapper {
    display: flex;
    align-items: center;
    .avatar {
      width: calc(#{$logoHeight} - 15px);
      height: calc(#{$logoHeight} - 15px);
      display: flex;
      align-items: center;
      & > img {
        border: 1px solid #f6f6f6;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
      }
    }
    .nick-name {
      margin-left: 10px;
      .tip {
        transform: rotate(0);
        transition: transform $transitionTime;
      }
    }
  }
}
.vaw-avatar-container:hover {
  cursor: pointer;
  .nick-name .tip {
    transform: rotate(180deg);
    transition: transform $transitionTime;
  }
}
.el-dropdown-menu {
  top: 40px !important;
}
</style>
